@tailwind base;

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: none;
    margin-bottom: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #EBEBEF;
    border-radius: 2px;
    border-right: 2px solid rgba(0, 0, 0, 0);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: black;
}

body {
    margin: 0;
    overscroll-behavior: none;
}

@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "DM Sans";
    src: url("../public/fonts/DMSans-Regular.ttf");
}

@font-face {
    font-family: "DM Sans Bold";
    src: url("../public/fonts/DMSans-Bold.ttf");
}

@font-face {
    font-family: "DM Sans Medium";
    src: url("../public/fonts/DMSans-Medium.ttf");
}